import React from "react";
import Content from '../views/PrivacyPolicy';
import terms from '../data/fleet-terms.json';

export default ({location}) => (
  <Content location={location} seo={{
      title: "FLEET salgsbetingelser",
      lang: 'en'
    }}
    title='FLEET salgsbetingelser'
    terms={terms}
    url='_url:fleet-terms'/>
);